<template>
                <el-table
                  ref="inputTable"
                  class="table-responsive table-flush position-table table-tablet"
                  header-row-class-name="thead-light"
                  :data="tabletItems"
                  row-key="id"
                  :row-class-name="tableRowClassName"
                  style="width: 100%; overflow: auto"
                >
                <el-table-column type="expand" width="100">
                  <template slot-scope="props">
                    <el-row>
                      <el-col>
                        <table>
                          <tr>
                            <th class="expanded-header">{{ $t('message.width') }}</th>
                            <th class="expanded-header">{{ $t('message.height') }}</th>
                            <th class="expanded-header">{{ $t('data_input.material_manager.perimeter_placeholder') }}</th>
                            <th class="expanded-header">{{ $t('message.unit') }}</th>
                            <th class="expanded-header"></th>
                            <th class="expanded-header">{{ $t('message.id') }}</th>
                            <th class="expanded-header">{{ $t('message.additionalInformation') }}</th>
                            <th class="expanded-header">{{ $t('message.result') }}</th>
                          </tr>
                          <tr>
                            <td class="expanded-row">{{ props.row.width }}</td>
                            <td class="expanded-row">{{ props.row.height }}</td>
                            <td class="expanded-row"><span v-if="props.row.extent">{{ props.row.extent | number }}</span></td>
                            <td class="expanded-row">{{ props.row.unit }}</td>
                            <td>
                              <el-tooltip
                                :content="$t('position.go-to-bhb')"
                                placement="top"
                                v-if="!props.row.additional"
                              >
                                <base-button
                                  type="link"
                                  size="sm"
                                  @click="
                                    $router.push({
                                      path: `/project/${$route.params.id}/bhb/${props.row.position_id}`,
                                    })
                                  "
                                >
                                  <span class="btn-inner--icon">
                                    <i class="fas fa-edit text-black"></i>
                                  </span>
                                </base-button>
                              </el-tooltip>
                            </td>
                            <td class="expanded-row">{{ props.row.pos.key }}</td>
                            <td class="expanded-row left">{{ props.row.pos.cl_info }}</td>
                            <td class="expanded-row">{{ props.row.rueck_result }}</td>
                          </tr>
                        </table>
                      </el-col>
                    </el-row>
                  </template>
                </el-table-column>
                  <el-table-column :label="$t('message.backNote')" width="200">
                    <template v-slot="{ row }">
                      <el-row>
                        <el-col :span="2">
                          <el-checkbox
                            :value="row.state_rueck == 'ZURUECK'"
                            @change="(value) => changeState(row, value)"
                            :disabled="
                              !currentUserRights.includes(
                                'Rückeinlagerungsliste_Abhaken'
                              ) || project.state === 'ARCHIVED'
                            "
                          ></el-checkbox>
                        </el-col>
                        <el-col :span="14" class="ml-2">
                          <el-select
                            v-model="row.rueck_result"
                            @change="changeResult(row)"
                            placeholder="Resultat"
                            :disabled="
                              !currentUserRights.includes(
                                'Rückeinlagerungsliste_Bemerkungen verfassen'
                              ) || project.state === 'ARCHIVED'
                            "
                          >
                            <el-option
                              v-for="option in tabletResults"
                              :key="option"
                              :label="option"
                              :value="option"
                            >
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="2">
                          <base-button
                            type="link"
                            size="md"
                            @click="showComments(row)"
                            :disabled="project.state === 'ARCHIVED'"
                          >
                            <span class="btn-inner--icon ml-2">
                              <i v-if="row.rueck_bemerkung" class="fas fa-comment"></i>
                              <i v-else class="far fa-comment text-black"></i>
                            </span>
                          </base-button>
                        </el-col>
                      </el-row>
                      <el-row v-if="row.user_rueck_ref">
                        <el-col :span="19">
                          {{ row.user_rueck_ref.name }},
                          {{ row.date_rueck | datetime }}
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>

                  <el-table-column
                    sortable
                    label="PT-NR."
                    width="100"
                    prop="pos.pt_number"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="category.name"
                    :label="$t('global.category')"
                    width="150"
                    sortable
                  >
                    <template v-slot="{row}">
                      {{ row | category(currentLocale) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.materialConstruction')"
                    width="220"
                    sortable
                    :sort-method="sortMaterial"
                  >
                    <template v-slot="{ row }">
                      <div v-if="row.article && !row.additional">
                        {{ row | material(currentLocale) }}
                      </div>
                      <div v-if="row.additional">
                        {{ row.article }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.amount')"
                    align="right"
                    header-align="right"
                    width="100"
                    prop="quantity"
                    sortable
                  >
                    <template v-slot="{ row }">
                      {{ row.quantity | number }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.result_customer')"
                    width="160"
                    prop="rueck_result_customer"
                    sortable
                  ></el-table-column>
                  
                </el-table>
 
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { setTimeout } from "timers";

export default {
  name: "ReStorageListMobile",
  computed: {
    ...mapGetters([
      "project",
      "positions",
      "projectAddressPositions",
      "currentProjectAddress",
      "pickingListPositions",
      "kommStateValues",
      "kommFilterValue",
      "currentUserRights",
      "openProjects",
      "currentUser",
      "currentLocale",
      "authorisedStateValues",
    ]),
  },
  props: {
    tabletItems: {
			type: [Array, Object],
    },
    tabletResults: {
			type: Array,
    }
  },
  data() {
    return {
    };
  },
  watch: {
    positions(val) {
      if (val !== null) {
        setTimeout(() => {}, 5000);
      }
    },
  },
  methods: {
    ...mapActions([
      "updatePositionSort",
      "updateProject",
      "loadAdditional",
      "getFullProject",
      "getProjectPersons"
    ]),
    sortMaterial(a, b) {
      let list1 = a.additional === true ? a.article : a.variant.InternalDescription;
      let list2 = b.additional === true ? b.article : b.variant.InternalDescription;

      if(list1 < list2) { return -1; }
      if(list1 > list2) { return 1; }

      return 0;
    },
    showComments(row) {
      this.$emit('showCommentsClick', row);
    },
    edit(row) {
      this.$emit('editClick', row);
    },
    askDelete(row) {
      this.$emit('deleteClick', row);
    },
    changeResult(row) {
      this.$emit('changeStateResult', row);
    },
    changeState(row, value = null) {
      this.$emit('changeStateClick', {item: row, value: value});
    },
    tableRowClassName({ row, rowIndex }) {
      let classes = "no-hover";
      if (this.$route.params.positionId) {
        let pos_item = parseInt(this.$route.params.positionId);
        if (pos_item === row.position_id || pos_item === row.id) classes += " highlighted";
      }
      if (row.state_komm === "GELADEN") classes += " loaded";
      if (rowIndex % 2 == 0) classes += " striped";
      return classes;
    },
  },
  mounted() {
  },
};
</script>
<style>
.table-tablet table.el-table__body td {
  padding-left: 0.5rem;
}
.table-tablet table.el-table__body .el-table__expanded-cell {
  background-color: #e8ebef;
}
.table-tablet table.el-table__body .expanded-row {
  text-align: center;
}.table-tablet table.el-table__body .expanded-row.left {
  text-align: left;
}
.table-tablet table.el-table__body .expanded-header {
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 1px;
}
.checkbox-lg .el-checkbox ::before,
.checkbox-lg .el-checkbox ::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .el-checkbox {
  padding-top: 13px;
  padding-left: 6px;
}

.checkbox-xl .el-checkbox ::before,
.checkbox-xl .el-checkbox ::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.project-selection {
  width:100%;
}

.el-table__footer-wrapper > .el-table__footer > tbody > tr > td {
  padding-right: 0;
}

.checkbox-xl .el-checkbox {
  padding-top: 23px;
  padding-left: 10px;
}
.el-table .striped {
  background: #fafafa !important;
}
.el-table .loaded {
  background: #dddddd !important;
}
.ride-name {
  display: inline-block;
  width: 150px;
  margin: 5px 0;
}
.el-table .highlighted {
  background: rgba(133, 193, 248, 0.3) !important;
}
.konfektion {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.konfektion-red {
  background-color: #f00;
}
.konfektion-yellow {
  background-color: #ff0;
}
.konfektion-green {
  background-color: #0f0;
}
</style>
