<template>
  <div>
    <base-header class="pb-6">
    <div class="py-2 tablet-show"></div>
      <div class="row align-items-center py-4 tablet-hide">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card no-body>
            <template slot="header">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">{{ $t("message.reStorageList") }}</h3>
                </div>
                <div class="col-6 text-right">

                  <compact-mode-toggle v-model="compactMode" class="tablet-hide" />

                  <base-button
                    class="pr-1"
                    size="sm"
                    type="primary"
                    @click="openExportModal"
                    v-if="
                      items.length > 0 &&
                      currentUserRights.includes('project.rueckeinlagerungsliste.canexport')
                    "
                  >
                    {{ $t("message.export") }}
                  </base-button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col pl-0">
                  <base-input
                    v-model="searchText"
                    :placeholder="$t('global.search')"
                  />
                </div>
              </div>
              <div class="row">
                <el-select
                  multiple
                  collapse-tags
                  class="mr-2 mb-1 select-primary"
                  :value="filterPtNumber"
                  @input="onFilterPtNumberChanged"
                  :placeholder="$t('message.PTNumberRange')"
                >
                  <el-option
                    v-for="option in ptNumbers"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>
                <el-select
                  multiple
                  collapse-tags
                  class="mr-2 select-primary"
                  :value="filterLocations"
                  @input="onFilterLocationsChanged"
                  :placeholder="$t('message.location')"
                >
                  <el-option
                    v-for="option in locations"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>
                <el-select
                  multiple
                  collapse-tags
                  class="mr-2 select-primary"
                  :value="filterCategories"
                  @input="onFilterCategoryChanged"
                  :placeholder="$t('global.category')"
                >
                  <el-option
                    v-for="option in categories"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>
                <el-select
                  class="select-primary mr-2"
                  v-model="filterValue"
                  placeholder="Status"
                >
                  <el-option
                    v-for="(option, index) in stateFilterValues"
                    class="select-primary ml-1"
                    :value="index"
                    :label="option"
                    :key="index"
                  >
                  </el-option>
                </el-select>
                <el-select
                  class="select-primary mr-2"
                  v-model="resultFilterValue"
                  placeholder="Resultat"
                >
                  <el-option label="Alle" value="ALLE" />
                  <el-option
                    v-for="option in results"
                    :key="option"
                    :label="option"
                    :value="option"
                  >
                  </el-option>
                </el-select>
                <base-checkbox
                  v-model="showRueck"
                  class="mr-2 mt-2"
                  style="display: inline-block"
                >
                  {{ $t("tasks.show-done-tasks") }}
                </base-checkbox>
              </div>
            </template>
            <template slot="footer">
              <div class="row">
                <div class="col-12 text-right"></div>
              </div>
            </template>

            <re-storage-list-mobile
                  class="tablet-show"
                  :tabletItems="items"
                  :tabletResults="results"
                  @changeStateResult="changeState"
                  @changeStateClick="(val) => changeState(val.item, val.value)"
                  @showCommentsClick="showComments"
                  @editClick="edit"
                  @deleteClick="askDelete"
                />
            <el-table
              ref="inputTable"
              class="table-responsive table-flush position-table tablet-hide"
              header-row-class-name="thead-light"
              :data="items"
              row-key="id"
              max-height="750"
              @selection-change="handleSelectionChange"
              :row-class-name="tableRowClassName"
              style="width: 100%"
            >
              <el-table-column :label="$t('message.backNote')" width="220">
                <template v-slot="{ row }">
                  <el-row>
                    <el-col :span="2">
                      <el-checkbox
                        :value="row.state_rueck == 'ZURUECK'"
                        @change="(value) => changeState(row, value)"
                        :disabled="(
                          !currentUserRights.includes('Rückeinlagerungsliste_Abhaken') ||
                          project.state === 'ARCHIVED' ||
                          (row.rueck_bemerkung && row.rueck_bemerkung_checked === null)
                          )
                        "
                      ></el-checkbox>
                    </el-col>
                    <el-col :span="14" class="ml-2">
                      <el-select
                        v-model="row.rueck_result"
                        @change="changeState(row)"
                        placeholder="Resultat"
                        :disabled="
                          !currentUserRights.includes(
                            'Rückeinlagerungsliste_Bemerkungen verfassen'
                          ) || project.state === 'ARCHIVED'
                        "
                      >
                        <el-option
                          v-for="option in results"
                          :key="option"
                          :label="option"
                          :value="option"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="2">
                      <base-button
                        type="link"
                        size="md"
                        @click="showComments(row)"
                        :disabled="project.state === 'ARCHIVED'"
                      >
                        <span class="btn-inner--icon ml-2">
                          <i v-if="row.rueck_bemerkung" class="fas fa-comment"></i>
                          <i v-else class="far fa-comment text-black"></i>
                        </span>
                      </base-button>
                    </el-col>
                  </el-row>
                  <el-row v-if="row.user_rueck_ref">
                    <el-col :span="19">
                      {{ row.user_rueck_ref.name }},
                      {{ row.date_rueck | datetime }}
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column v-if="!compactMode">
                <template v-slot="{ row }">
                  <el-tooltip
                    :content="$t('position.go_to_im')"
                    placement="top"
                    v-if="!row.additional"
                  >
                    <base-button
                      type="link"
                      size="sm"
                      @click="
                        $router.push({
                          path: `/project/${$route.params.id}/bhb/${row.position_id}`,
                        })
                      "
                      :disabled="project.state === 'ARCHIVED'"
                    >
                      <span class="btn-inner--icon">
                        <i class="fas fa-edit text-black"></i>
                      </span>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column
                v-if="!compactMode"
                sortable
                prop="pos.key"
                label="ID"
                width="100"
              >
                <template v-slot="{ row }">
                  {{ row.pos.key }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('position.pt_number')"
                width="120"
                prop="pos.pt_number"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.pos.pt_number }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('global.category')"
                width="160"
                prop="category.name"
                sortable
              >
                <template v-slot="{row}">
                  {{ row | category(currentLocale) }}
                </template>
              </el-table-column>
              <el-table-column
                v-if="!compactMode"
                prop="pos.bhb_position.name"
                :label="$t('message.positionBHB')"
                width="130"
                sortable
              >
              </el-table-column>

              <el-table-column
                :label="$t('message.materialConstruction')"
                width="250"
                sortable
                :sort-method="sortMaterial"
              >
                <template v-slot="{ row }">
                  <div v-if="row.article && !row.additional">
                    {{ row | material(currentLocale) }}
                  </div>
                  <div v-if="row.additional">
                    {{ row.article }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="!compactMode"
                :label="$t('message.unit')"
                width="100"
                prop="unit"
                sortable
              ></el-table-column>
              <el-table-column
                :label="$t('message.amount')"
                align="right"
                header-align="right"
                width="100"
                prop="quantity"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.quantity | number }}
                </template>
              </el-table-column>
              <el-table-column
                align="right"
                :label="$t('message.width')"
                width="100"
              >
                <template v-slot="{ row }">
                  {{ row.width | number }}
                </template>
              </el-table-column>
              <el-table-column
                align="right"
                :label="$t('message.height')"
                width="100"
              >
                <template v-slot="{ row }">
                  {{ row.height | number }}
                </template>
              </el-table-column>
              <el-table-column
                align="right"
                :label="$t('data_input.material_manager.perimeter_placeholder')"
                width="100"
              >
                <template v-slot="{ row }">
                  <span v-if="row.extent">
                    {{ row.extent | number }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('message.additionalInformation')"
                prop="pos.cl_info"
                width="180"
              ></el-table-column>
              <el-table-column
                :label="$t('message.result_customer')"
                width="160"
                prop="rueck_result_customer"
                sortable
              ></el-table-column>
              <el-table-column
                :label="$t('message.result')"
                width="150"
                prop="rueck_result"
                sortable
              ></el-table-column>
            </el-table>
          </card>
        </div>
      </div>
    </div>

    <modal :show.sync="showCommentModal">
      <h6 slot="header" class="modal-title">
        {{ $t("message.notice") }}
      </h6>
      <div>
        <el-input
          type="textarea"
          rows="5"
          placeholder=""
          @change="(value) => changeState(commentItem)"
          v-model="commentItem.rueck_bemerkung"
          :disabled="
            !currentUserRights.includes(
              'Rückeinlagerungsliste_Bemerkungen verfassen'
            ) || project.state === 'ARCHIVED'
          "
        ></el-input>
      </div>
      <div class="mt-3">
        <el-checkbox
          v-if="(this.isPL && commentItem.rueck_bemerkung && commentItem.rueck_bemerkung_checked === null)"
          @change="checkComment(commentItem)"
          class="checkbox-lg"
          :label="$t('message.comment_checked_by_pl')"
        ></el-checkbox>
        <p v-if="(commentItem.rueck_bemerkung_checked !== null)">
          <i class="fas fa-check"></i>
          {{ $t("message.komm_checked") }} {{ commentItem.rueck_bemerkung_checked | datetime }}
        </p>
        <p v-if="(!this.isPL && commentItem.rueck_bemerkung && commentItem.rueck_bemerkung_checked === null)">
          <i class="fas fa-info-circle"></i>
          {{ $t("message.comment_unchecked") }}
        </p>
      </div>
      <p v-if="commentItem.user_komm_ref" class="mt-3 mb-1">
        {{ $t("message.bhbState.KOMMISSIONIERT") }}: 
        {{ commentItem.user_komm_ref.name }},
        {{ commentItem.date_komm | datetime }}
      </p>
      <p v-if="commentItem.user_lade_ref" class="mb-1">
        {{ $t("message.bhbState.GELADEN") }}: 
        {{ commentItem.user_lade_ref.name }},
        {{ commentItem.date_lade | datetime }}
      </p>
      <p v-if="commentItem.user_rueck_ref">
        {{ $t("message.bhbState.ZURUECK") }}: 
        {{ commentItem.user_rueck_ref.name }},
        {{ commentItem.date_rueck | datetime }}
      </p>
      <template slot="footer">
        <base-button
          type="link"
          class="ml-auto"
          @click="showCommentModal = false"
          >{{ $t("message.close") }}</base-button
        >
      </template>
    </modal>

    <re-storage-list-export-form v-if="exportModal" :show.sync="exportModal" :items="items" />

  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { setTimeout } from "timers";
import ReStorageListExportForm from "./ReStorageListExportForm";
import ReStorageListMobile from "./ReStorageListMobile";
import moment from "moment";
import { debounce } from "lodash";

export default {
  name: "DataInput",
  components: {
    RouteBreadCrumb,
    ReStorageListExportForm,
    ReStorageListMobile,
  },
  computed: {
    ...mapGetters([
      "project",
      "positions",
      "projectAddressPositions",
      "currentProjectAddress",
      "reStrorageListPositions",
      "ruecklagerungFilterValue",
      "ruecklagerungStateValues",
      "currentUserRights",
      "currentUser",
      "currentLocale",
    ]),
    totalPrice: function () {
      return this.reStrorageListPositions.reduce((val, pos) => {
        return val + Number.parseFloat(pos.price_total);
      }, 0);
    },
    stateFilterValues() {
      const temp = [...new Set(this.ruecklagerungStateValues)];
      temp.unshift("ALLE");
      return temp.map((i) => this.$t("message.bhbState." + i));
    },
    filterValue: {
      get() {
        return this.ruecklagerungFilterValue;
      },
      set(val) {
        this.$store.commit("ruecklagerungFilterValue", val);
      },
    },
    items() {
      let additional = this.additional;
      if (this.currentProjectAddress) {
        additional = additional.filter(
          (ai) => ai.address.address_id === this.currentProjectAddress
        );
      }

      let result = this.reStrorageListPositions.concat(additional);

      if (this.filterRides.length > 0) {
        result = result.filter((item) => {
          if (item.ride) {
            return this.filterRides.includes(item.ride.vehicle.license);
          }
          return false;
        });
      }

      if (this.searchText) {
        const searchText = this.searchText.toLowerCase();
        result = result.filter((r) => {
          return (
            r.pos.pt_number?.toLowerCase().includes(searchText) ||
            r?.pos.key.toLowerCase().includes(searchText) ||
            r?.variant?.InternalDescription?.toLowerCase().includes(searchText) ||
            r?.rueck_bemerkung?.toLowerCase().includes(searchText) ||
            r?.pos.bhb_position?.name?.toLowerCase().includes(searchText) ||
            r?.pos.cl_info?.toLowerCase().includes(searchText) ||
            r?.quantity?.toString().includes(searchText) ||
            r?.width?.toString().includes(searchText) ||
            r?.height?.toString().includes(searchText) ||
            (typeof r?.article === "string"
              ? r?.article?.toLowerCase().includes(searchText)
              : false)
          );
        });
      }

      if (this.filterPtNumber.length > 0) {
        result = result.filter((item) =>
          this.filterPtNumber.includes(item.pt_number)
        );
      }

      if (this.filterLocations.length > 0) {
        result = result.filter((item) => {
          if (item.pos.address) {
            return this.filterLocations.includes(item.pos.address.address.name);
          }
          return false;
        });
      }

      if (this.filterCategories.length > 0) {
        result = result.filter((item) => {
          if (item.category) {
            const cat = this.$i18n.locale === 'de' ? item.category.name : item.category.name_en;
            return this.filterCategories.includes(cat);
          }
          return false;
        });
      }

      if (!this.showRueck) {
        result = result.filter((item) => item.state_rueck !== "ZURUECK");
      }

      result = result
        .filter(
          (item) =>
            item.state_lade === "GELADEN" &&
            (this.filterValue == 0
              ? true
              : item.state_rueck ==
                this.ruecklagerungStateValues[this.filterValue - 1])
        )
        .filter((i) =>
          this.resultFilterValue !== "ALLE"
            ? i.rueck_result === this.resultFilterValue
            : true
        )
        .sort((a, b) => {
          const al = a.state_rueck === "ZURUECK";
          const bl = b.state_rueck === "ZURUECK";
          if (al == bl) {
            if (a.additional && !b.additional) return +1;
            if (!a.additional && b.additional) return -1;
            return a.id - b.id;
          }
          return al ? +1 : -1;
        });

      return result;
    },
    rides() {
      const items = this.reStrorageListPositions.concat(this.additional);
      return [
        ...new Set(
          items.map((item) => (item.ride ? item.ride.vehicle.license : "Leer"))
        ),
      ];
    },
    ptNumbers() {
      const items = this.reStrorageListPositions.concat(this.additional);
      return [
        ...new Set(
          items.map((item) => (item.pt_number ? item.pt_number : "Leer"))
        ),
      ];
    },
    locations() {
      const items = this.reStrorageListPositions.concat(this.additional);
      return [
        ...new Set(
          items.map((item) =>
            item.pos.address ? item.pos.address.address.name : "Leer"
          )
        ),
      ];
    },
    categories() {
      const items = this.reStrorageListPositions.concat(this.additional);
      return [
        ...new Set(
          items.map((item) => (item.category ? (this.$i18n.locale === 'de' ? item.category.name :item.category.name_en) : "Leer"))
        ),
      ];
    },
    searchText: {
      get() {
        return this.search;
      },
      set: debounce(function (val) {
        this.search = val;
      }, 500),
    },
  },
  data() {
    return {
      compactMode: true,
      enableSorting: true,
      showPriceList: false,
      sel: [],
      changed: [],
      filterText: "Alle",
      resultFilterValue: "ALLE",
      additional: [],
      results: [
        "EINGELAGERT",
        "ENTSORGT",
        "BESCHAEDIGT",
        "FEHLT",
        "AN KUNDE VERSENDET",
      ],
      exportModal: false,
      filterRides: [],
      filterLocations: [],
      filterPtNumber: [],
      filterCategories: [],
      showRueck: false,
      search: "",
      showCommentModal: false,
      commentItem: {},
      isPL: false,
    };
  },
  watch: {
    positions(val) {
      if (val !== null) {
        setTimeout(() => {}, 5000);
      }
    },
    project() {
      this.reloadAdditional();
    },
  },
  methods: {
    ...mapActions([
      "updatePositionSort",
      "loadAdditional",
      "loadPositionsForPorject",
      "getProjectPersons"
    ]),
    showRides(row) {
      this.showRidesModal = true;
      if (row.pos.ride !== null) {
        this.ridesList = 
          {
            'title': row.pos.address.address.name +', '+ row.pos.address.address.city,
            'rides': {
            'Laden': row.pos.ride.load,
            'Abfahrt': row.pos.ride.departure,
            'Abladen vor Ort': row.pos.ride.uload,
            'Montagebeginn': row.pos.ride.assembly,
            'Aufladen vor Ort': row.pos.ride.load_back,
            'Abladen bei APA': row.pos.ride.unload_back
            }
          };
      }
    },
    showComments(row) {
      this.getPL().then(
        result => {
          this.isPL = result.length ? true : false;
        }
      );
      this.showCommentModal = true;
      this.commentItem = row;
    },
    sortMaterial(a, b) {
      let list1 = a.additional === true ? a.article : a.variant.InternalDescription;
      let list2 = b.additional === true ? b.article : b.variant.InternalDescription;

      if(list1 < list2) { return -1; }
      if(list1 > list2) { return 1; }

      return 0;
    },
    async getPL() {
      if (this.project) {
        const persons = await this.getProjectPersons(this.project.id);
        let pl = persons.filter(
          (p) => (p.user_id === this.currentUser.id && p.topic === 'PL')
        )
      return pl;
      }
    },
    onFilterRidesChanged(e) {
      this.filterRides = [...new Set(e)];
    },
    onFilterLocationsChanged(e) {
      this.filterLocations = [...new Set(e)];
    },
    onFilterPtNumberChanged(e) {
      this.filterPtNumber = [...new Set(e)];
    },
    onFilterCategoryChanged(e) {
      this.filterCategories = [...new Set(e)];
    },
    openExportModal() {
      this.exportModal = true;
    },
    edit(row) {
      this.$router.push({
        path: `/project/${this.$route.params.id}/data/${row.id}`,
      });
    },
    addItem() {
      this.$router.push({ path: `/project/${this.$route.params.id}/data/add` });
    },
    copySelection() {},
    deleteSelection() {},
    handleSelectionChange(sel) {
      this.sel = sel;
    },
    changeState(item, value = null) {
      if (value !== null) {
        item.user_rueck_ref = this.currentUser;
        item.date_rueck = new Date();
      }

      item.state_rueck = state(item.rueck_bemerkung);

      const action = item.additional
        ? "updateMaterial_additional"
        : "updateMaterial";

      if (item.ids) {
        item.ids.forEach((id) => {
          this.$store.dispatch(action, {
            id,
            data: {
              state_rueck: item.state_rueck,
              rueck_bemerkung: item.rueck_bemerkung,
              rueck_result: item.rueck_result,
            },
          });
        });
      } else {
        this.$store.dispatch(action, {
          id: item.id,
          data: {
            state_rueck: item.state_rueck,
            rueck_bemerkung: item.rueck_bemerkung,
            rueck_result: item.rueck_result,
          },
        });
      }

      function state(bemerkung) {
        if (value) {
          return "ZURUECK";
        } else if (bemerkung) {
          return "BEMERKUNG";
        } else {
          return "OFFEN";
        }
      }
    },
    checkComment(item) {
      item.rueck_bemerkung_checked = moment().format("YYYY-MM-DD HH:mm:ss");
      const action = item.additional
        ? "updateMaterial_additional"
        : "updateMaterial";

      this.$store.dispatch(action, {
          id: item.id,
          data: {
            rueck_bemerkung_checked: item.rueck_bemerkung_checked
          },
        });
    },
    tableRowClassName({ row, rowIndex }) {
      let classes = "no-hover";
      if (row.state_rueck == "ZURUECK") classes += " back";
      else if (rowIndex % 2 == 0) classes += " striped";
      return classes;
    },
    category(row) {
      if (row.mat == 1) {
        return row.pos.category1 ? row.pos.category1.name : "";
      } else {
        return row.pos.category2 ? row.pos.category2.name : "";
      }
    },
    async reloadAdditional() {
      if (this.project) {
        this.additional = await this.loadAdditional(this.project.id);
      }
    },
  },
  mounted() {
    this.$refs.inputTable.sort("Area", "ASC");
    this.reloadAdditional();
    this.loadPositionsForPorject(this.$route.params.id);
  },
};
</script>
<style>
.el-table .striped {
  background: #fafafa !important;
}
.el-table .back {
  background: #dddddd !important;
}
.tablet-show {
  display: none;
}
@media (max-width: 1200px) {
  .tablet-hide {
    display: none;
  }
  .tablet-show {
    display: inline-block;
  }
}
</style>
